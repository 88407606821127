@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1c1c1c;
  font-family: 'LuckiestGuy', 'sans-serif', 'Bunguee', 'Roboto';
  background-image: url('./assets/Fundo-02.png');
}

@font-face {
  font-family: 'LuckiestGuy';
  src: url('./assets/fonts/LuckiestGuy.woff') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.background-image-container {
  background-image: url('./assets/Fundo-02.png');
  background-size: cover;
  /* cover or contain */
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  /* 100% da altura da viewport */
  width: 100%;
}